<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-1)">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级抽查维护</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <span>{{query.projectCode}}</span>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <span>{{query.projectName}}</span>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="handlAdd()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="100" />
              <el-table-column label="抽查日期" align="center" prop="spotCheckTime" show-overflow-tooltip min-width="140" />
              <el-table-column label="操作" align="center" width="150">
                <div slot-scope="{row}" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="preview(row)">预览</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="download(row)">下载</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="handlDelete(row.spotCheckId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog title="班级抽查" :visible.sync="dialogData.status" v-if="dialogData.status" :close-on-click-modal="false" width="400px" @close="closeModel">
      <el-form ref="dialogData" :model="dialogData" style="width: 100%" :rules="rules">
        <el-form-item label="抽查日期" label-width="100px" prop="spotCheckTime">
          <!-- 也不知道为啥要设置flex导致错位 ↓style -->
          <el-date-picker
            size="small"
            style="display: inline-block!important;"
            v-model="dialogData.spotCheckTime"
            type="date"
            value="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="pdf预览"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
    >
      <div id="pdf-cert" style="height: 750px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "qualificationsType",
  components: {
    Empty,
  },
  mixins: [List],
  doNotInit:true,
  data () {
    return {
      spotCheckTime: "", //抽查日期
      dialogData: {
        status: false,
        spotCheckTime: '',
        spotCheckId: '',
      },
      rules: {
        spotCheckTime: [
          { required: true, message: "请选择抽查日期", trigger: "change" },
        ],
      },
      query:{
        projectId:'',
        projectCode:'',
        projectName:'',
      },
      dialogCert: false,
    };
  },
  created () {
    this.query = this.$route.query
    this.getData()
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData () {
      const params = {
        projectId: this.query.projectId,
      };
      this.doFetch(
        {
          url: "/biz/project/spot/check/list",
          params,
        }
      );
    },
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    /* 新增 */
    handlAdd () {
      this.dialogData.status = true
    },
    /* 删除 */
    handlDelete (spotCheckId) {
      this.doDel(
        {
          url: "/biz/project/spot/check/delete",
          msg: "你确认删除该抽查记录吗？",
          ps: {
            type: "post",
            data: { spotCheckId },
          },
        }
      );
    },
    preview(row) {
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(row.docFileUrl, "#pdf-cert");
      });
    },
    download(row) {
      window.open(row.docFileDownloadUrl)
    },
    doOk () {
      this.$refs["dialogData"].validate((valid) => {
        if (valid) {
          this.$post('/biz/project/spot/check/save', {...this.dialogData,projectId: this.query.projectId,}).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less"></style>
